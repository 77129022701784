import React, { useState } from 'react'
import { Helmet } from "react-helmet"
import './styles.scss'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'

import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'
import ThemeContext from '../../context'

class Office extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
        name: "",
        email: "",
        phone: "",
        message: "",
        error: false,
        show: false
    }
    this.show = this.show.bind(this)
}
static contextType = ThemeContext


show() {
  this.setState({show : true})
}

check(val) {
    if (this.state.error && val === "") {
            return false
    } else {
        return true
    }
}

submit() {
    if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
        this.setState({error: true})
    } else {
        this.setState({error: true})
    }
}
render() {
  
    return (
        <section id={`${this.props.id}`} className="office" style={{height: this.context.height}}>
            <Row>
                <Col md={2} className="side">
                    <h2>
                        <BaffleText text="Login" revealDuration={500} revealDelay={500} parentMethod={this.show} callMethodTime={1100} />
                    </h2>
                </Col>
                <Col md={5} className="form">
                    {this.form()}
                </Col>

            </Row>
        </section>
    )
}


form() {
    if (this.state.show || this.context.height === "auto") {
        return (
            <AnimationContainer delay={0} animation="fadeInUp fast">
            <div className="form-container">
                <div className="line-text">
                    <h4>Aparnia Think Tank</h4>
                    <AnimationContainer delay={50} animation="fadeInUp fast">
                        <div className="form-group">
                        <input type="text" className={`email ${this.check(this.state.email) ? "" : "error"}`} placeholder="Email" onChange={e => this.setState({email: e.target.value})} />
                        </div>
                    </AnimationContainer>
                    <AnimationContainer delay={100} animation="fadeInUp fast">
                    <div className="form-group">
                    <input type="password" className={`name ${this.check(this.state.name) ? "" : "error"}`} placeholder="Password" onChange={e => this.setState({name: e.target.value})} />
                        
                    </div>
                    </AnimationContainer>
                    <AnimationContainer delay={150} animation="fadeInUp fast">
                        <div className="submit">
                            <button className={`hover-button ${this.state.error ? "error" : ""}`} onClick={() => this.submit()}>
                            <span>Sign In</span>
                        </button>
                        </div>
                    </AnimationContainer>

                </div>
            </div>
            </AnimationContainer>
        )
    }
}

  componentDidMount() {
    try {
     // window.jQuery = $; window.$ = $;
 /*  if(typeof window !== 'undefined'){
      let ripples = $.fn.ripples; //accessing jquery function
      $('body').ripples({ //call directly on mount
        resolution: 1024,
        dropRadius: 25,
        perturbance: 0.10,
      });
    }*/
    // window.externalLibrary.method()
    } catch (e) {
      console.error(e);
    }

  }

  icons() {
    return this.props.icons.edges.map((value, index) => {
      return (
        <img
          src={value.node.childImageSharp.fluid.src}
          className={`animated fadeIn move-${
            Math.floor(Math.random() * 10) % 2 === 0 ? 'up' : 'down'
            } float-image`}
          style={{
            left: `${index * 10}%`,
            bottom: `${Math.random() *
              (+(index % 2 === 0 ? 80 : 20) - +(index % 2 === 0 ? 70 : 10)) +
              +(index % 2 === 0 ? 70 : 10)}%`,
          }}
          alt="shape"
          key={index}
        />
      )
    })
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "one.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Office icons={icons} mainImg={Img} {...props} />}
  />
)