import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import LayoutSecond from 'components/layoutsecond'
import Spinner from 'components/spinner'
import Hero from 'sections/hero'
import About from 'sections/about'
import Portfolio from 'sections/portfolio'
import Office from 'sections/office'
import Services from 'sections/services'
import Testimonials from 'sections/testimonials'
import Contact from 'sections/contact'

class OfficePage extends React.Component {

  render() {
    const { site } = this.props.data
    return (
      <div>
        <Helmet bodyAttributes={{style: 'background-color : #000!important; background-image:none'}}>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />

        </Helmet>
        <LayoutSecond>
            <Office id="home" />
      {/*      <About id="about" />
            <Services id="services" />
            <Portfolio id="portfolio" />
            <Team id="team" />
            <Testimonials id="testimonials" />
    <Contact id="contact" />  */}
        </LayoutSecond>
        <Spinner duration={1000} />
      </div>
    )
  }
}

export default OfficePage

export const pageQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`
